import { Component, inject, input, output, signal, viewChild } from "@angular/core";

import { DataTableComponent } from "../../../../shared/components";
import { Constants } from "../../../../shared/constants/constants";
import type { TableButtonClicked } from "../../../../shared/models";
import { ItemApiService } from "../../../../shared/services";

@Component({
  selector: "adaa-objective-table-view",
  standalone: true,
  imports: [DataTableComponent],
  template: `
    <adaa-data-table
      #table
      [disablePagination]="tableData().length < 10"
      [titleHeader]="titleHeader()"
      [configKey]="tableConfList()"
      [enableFilter]="false"
      [enableAdd]="false"
      [enablePrint]="true"
      [enableDeleteAction]="false"
      [enableEditAction]="false"
      [enableConnectionsAction]="false"
      [enableViewAction]="true"
      [localData]="tableData()"
      [isLocalData]="true"
      (actionClicked)="viewClicked.emit($event.data)"
    />
  `,
})
export class ObjectiveTableViewComponent {
  private readonly _itemApiService = inject(ItemApiService);

  titleHeader = input.required<string>();
  tableConfList = input.required<string>();
  objectiveId = input.required<number>();
  sourceType = input.required<number>();

  tableData = signal<object[]>([]);

  viewClicked = output<TableButtonClicked["data"]>();

  table = viewChild<DataTableComponent>("table");

  public refresh(periodId: number) {
    this._itemApiService
      .getAllByParentIdAndType({
        periodId: periodId as number | undefined,
        itemId: this.objectiveId(),
        sourceType: this.sourceType(),
        currentType: Constants.CONSTANT_OBJECTIVETYPE,
      })
      .subscribe({
        next: (res) => {
          this.tableData.set((res.responseData ?? []) as object[]);
        },
      });
  }
}
