import { inject } from "@angular/core";
import { type Route, Router } from "@angular/router";

import { checkIfLoggedInFn, NotArchivedPlan } from "../../../core/guards";
import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../shared/constants/enums";
import { mainObjectiveGuards, objectiveGuards, submenuGuards } from "../_guard";

const oldNewDSRoute: Route[] = [
  {
    path: "create",
    canActivate: [checkIfLoggedInFn, ...objectiveGuards.create, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.objectives.main_create",
      breadcrumb: "breadcrumbs.objectives.main_create",
      pageMode: PageMode.create,
      objectiveType: Constants.CONSTANT_STRATEGIC_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "edit/:id",
    canActivate: [checkIfLoggedInFn, ...objectiveGuards.edit, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.objectives.main_edit",
      breadcrumb: "breadcrumbs.objectives.main_edit",
      pageMode: PageMode.edit,
      objectiveType: Constants.CONSTANT_STRATEGIC_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: ":id",
    canActivate: [checkIfLoggedInFn, ...objectiveGuards.view],
    data: {
      title: "breadcrumbs.view_objective",
      breadcrumb: "breadcrumbs.view_objective",
      type: Constants.CONSTANT_STRATEGIC_OBJECTIVE,
    },
    loadComponent: () => import("../objectives-view/objectives-view.component").then((m) => m.ObjectivesViewComponent),
  },
];

const strategicObjectiveRoutes: Route[] = [
  {
    path: "strategic-objective/create",
    canActivate: [checkIfLoggedInFn, ...objectiveGuards.create, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.objectives.strategic_create",
      breadcrumb: "breadcrumbs.objectives.strategic_create",
      pageMode: PageMode.create,
      enabler: AdaaBoolean.N,
      objectiveType: Constants.CONSTANT_STRATEGIC_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "strategic-objective/edit/:id",
    canActivate: [checkIfLoggedInFn, ...objectiveGuards.edit, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.objectives.strategic_edit",
      breadcrumb: "breadcrumbs.objectives.strategic_edit",
      pageMode: PageMode.edit,
      enabler: AdaaBoolean.N,
      objectiveType: Constants.CONSTANT_STRATEGIC_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "strategic-objective/:id",
    data: {
      title: "breadcrumbs.objectives.strategic_view",
      breadcrumb: "breadcrumbs.objectives.strategic_view",
      enabler: AdaaBoolean.N,
      type: Constants.CONSTANT_STRATEGIC_OBJECTIVE,
    },
    canActivate: [checkIfLoggedInFn, ...objectiveGuards.view],
    loadComponent: () => import("../objectives-view/objectives-view.component").then((m) => m.ObjectivesViewComponent),
  },
];

const mainObjectiveRoutes: Route[] = [
  {
    path: "main-objective/create",
    canActivate: [checkIfLoggedInFn, ...mainObjectiveGuards.create, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.objectives.main_create",
      breadcrumb: "breadcrumbs.objectives.main_create",
      pageMode: PageMode.create,
      enabler: AdaaBoolean.N,
      objectiveType: Constants.CONSTANT_MAIN_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "main-objective/edit/:id",
    canActivate: [checkIfLoggedInFn, ...mainObjectiveGuards.edit, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.objectives.main_edit",
      breadcrumb: "breadcrumbs.objectives.main_edit",
      pageMode: PageMode.edit,
      enabler: AdaaBoolean.N,
      objectiveType: Constants.CONSTANT_MAIN_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "main-objective/:id",
    canActivate: [checkIfLoggedInFn, ...mainObjectiveGuards.view],
    data: {
      title: "breadcrumbs.objectives.main_view",
      breadcrumb: "breadcrumbs.objectives.main_view",
      enabler: AdaaBoolean.N,
      type: Constants.CONSTANT_MAIN_OBJECTIVE,
    },
    loadComponent: () => import("../objectives-view/objectives-view.component").then((m) => m.ObjectivesViewComponent),
  },
];

export const entityObjectiveRoutes: Route = {
  path: "objectives",
  canActivate: [
    checkIfLoggedInFn,
    () => {
      const router = inject(Router);

      return AdaaHelper.isPMOEntity() ? router.createUrlTree(["/console/pmo-objectives"]) : true;
    },
  ],
  children: [
    {
      path: "",
      canActivate: [checkIfLoggedInFn, ...submenuGuards],
      data: {
        title: "breadcrumbs.objectives.objectives",
        breadcrumb: "breadcrumbs.objectives.objectives",
        tableConfList: "objectives_conf_list",
        enabler: AdaaBoolean.N,
        itemTypeId: Constants.CONSTANT_PERMISSIONS.OBJECTIVES,
      },
      loadComponent: () =>
        import("../objectives-list/objectives-list.component").then((m) => m.ObjectivesListComponent),
    },
    ...mainObjectiveRoutes,
    ...strategicObjectiveRoutes,
    ...oldNewDSRoute,
  ],
};
