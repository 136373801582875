import { inject } from "@angular/core";
import { type Route, Router } from "@angular/router";

import { checkIfLoggedInFn, NotArchivedPlan } from "../../../core/guards";
import { AdaaHelper } from "../../../core/utils";
import { Constants } from "../../../shared/constants/constants";
import { AdaaBoolean, PageMode } from "../../../shared/constants/enums";
import { submenuGuards } from "../_guard";

const enablerObjectiveRoutes: Route[] = [
  {
    path: "enabler-objective/create",
    canActivate: [checkIfLoggedInFn, ...submenuGuards, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.enablerobjectives.new",
      breadcrumb: "breadcrumbs.enablerobjectives.new",
      pageMode: PageMode.create,
      enabler: AdaaBoolean.Y,
      objectiveType: Constants.CONSTANT_ENABLER_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "enabler-objective/edit/:id",
    canActivate: [checkIfLoggedInFn, ...submenuGuards, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.enablerobjectives.edit",
      breadcrumb: "breadcrumbs.enablerobjectives.edit",
      pageMode: PageMode.edit,
      enabler: AdaaBoolean.Y,
      objectiveType: Constants.CONSTANT_ENABLER_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "enabler-objective/:id",
    canActivate: [checkIfLoggedInFn, ...submenuGuards],
    data: {
      title: "breadcrumbs.enablerobjectives.view",
      breadcrumb: "breadcrumbs.enablerobjectives.view",
      enabler: AdaaBoolean.Y,
      type: Constants.CONSTANT_ENABLER_OBJECTIVE,
    },
    loadComponent: () => import("../objectives-view/objectives-view.component").then((m) => m.ObjectivesViewComponent),
  },
];

const transformationalObjectiveRoute: Route[] = [
  {
    path: "transformational-objective/create",
    canActivate: [checkIfLoggedInFn, ...submenuGuards, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.objectives.transformational_create",
      breadcrumb: "breadcrumbs.objectives.transformational_create",
      pageMode: PageMode.create,
      enabler: AdaaBoolean.N,
      objectiveType: Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "transformational-objective/edit/:id",
    canActivate: [checkIfLoggedInFn, ...submenuGuards, NotArchivedPlan()],
    data: {
      title: "breadcrumbs.objectives.transformational_edit",
      breadcrumb: "breadcrumbs.objectives.transformational_edit",
      pageMode: PageMode.edit,
      enabler: AdaaBoolean.N,
      objectiveType: Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE,
    },
    loadComponent: () =>
      import("../objectives-editor/objectives-editor.component").then((m) => m.ObjectivesEditorComponent),
  },
  {
    path: "transformational-objective/:id",
    canActivate: [checkIfLoggedInFn, ...submenuGuards],
    data: {
      title: "breadcrumbs.objectives.transformational_view",
      breadcrumb: "breadcrumbs.objectives.transformational_view",
      enabler: AdaaBoolean.N,
      type: Constants.CONSTANT_TRANSFORMATIONAL_OBJECTIVE,
    },
    loadComponent: () => import("../objectives-view/objectives-view.component").then((m) => m.ObjectivesViewComponent),
  },
];

export const pmoObjectivesRoutes: Route = {
  path: "pmo-objectives",
  canActivate: [
    checkIfLoggedInFn,
    () => {
      const router = inject(Router);

      return !AdaaHelper.isPMOEntity() ? router.createUrlTree(["/console/objectives"]) : true;
    },
  ],
  children: [
    {
      path: "",
      canActivate: [checkIfLoggedInFn, ...submenuGuards],
      data: {
        title: "breadcrumbs.objectives.objectives",
        breadcrumb: "breadcrumbs.objectives.objectives",
        tableConfList: "enabler_objectives_conf_list",
        enabler: AdaaBoolean.Y,
        itemTypeId: Constants.CONSTANT_PERMISSIONS.OBJECTIVES,
      },
      loadComponent: () =>
        import("../objectives-list/objectives-list.component").then((m) => m.ObjectivesListComponent),
    },
    ...transformationalObjectiveRoute,
    ...enablerObjectiveRoutes,
  ],
};
